import { useState } from "react";
import styled from "@emotion/styled/macro";
import { useLocation } from "react-router-dom";

import { Order, OrderType } from "models/market";
import { Card, TabsBar, Tab, Button } from "components";
import { WalletData } from "models/WebsocketClients/WalletData";
import { mediaQuery } from "utils";
import { config } from "config";
import { useAuth } from "providers";
import { TxForm } from "../TxForm";

const { path } = config;

const CardView = styled(Card)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 16px 0;
  background-color: ${(props) => props.theme.baseBackgroundColor};
`;

const Sell = styled.div`
  margin-left: 11px;
  padding: 24px 16px 24px 0px;
`;

const Buy = styled.div`
  margin-right: 11px;
  padding: 24px 0px 24px 16px;
`;

const TabsStyle = styled(TabsBar)`
  width: 100%;
  .tab-ul {
    width: fit-content;
    .tab {
      padding-bottom: 8px;
    }
  }
  li.is-active {
    color: ${(props) => props.theme.textColor};
  }
`;

const TabHeader = styled.h2`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 16px;
`;

const BuySellContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  ${mediaQuery("tablet")} {
    grid-template-columns: 50% 50%;
  }
`;

const DisabledButtonMock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
  margin: 0 16px; // match with CardView padding
  color: ${(props) => props.theme.darkgray};
  background-color: ${(props) => props.theme.primaryButtonDisable};
  border-radius: ${(props) => props.theme.borderRadiusBase};
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export interface ITrade {
  className?: string;
  onOrder: (order: Order) => void;
  base?: WalletData;
  quote?: WalletData;
  disabled: boolean;
}

const Trade = ({ className, onOrder, base, quote, disabled }: ITrade) => {
  const location = useLocation();
  const [activeTab, setactiveTab] = useState<OrderType>("limit");

  const {
    state: { isLoggedIn },
  } = useAuth();

  const sell = (orderSell: Order) => {
    if (activeTab === "limit" && orderSell.total) {
      onOrder({
        ...orderSell,
        type: activeTab,
      });
    } else if (activeTab === "market") {
      onOrder({
        ...orderSell,
        type: activeTab,
      });
    }
  };

  const buy = (orderBuy: Order) => {
    // todo rewrite this
    if (activeTab === "limit" && orderBuy.total) {
      onOrder({
        ...orderBuy,
        type: activeTab,
      });
    } else if (activeTab === "market") {
      onOrder({
        ...orderBuy,
        type: activeTab,
      });
    }
  };

  return (
    <CardView className={className}>
      {isLoggedIn ? (
        <TabsStyle
          overflow="visible"
          mode="horizontal"
          onSelect={(k: string | null) => k && setactiveTab(k as OrderType)}
        >
          {/* TODO: Maybe rewrite this into fake tabs (buttons) */}
          <Tab
            layoutId="trade"
            key="limit"
            label={<TabHeader>Limit</TabHeader>}
          >
            <BuySellContainer>
              <Buy>
                <TxForm
                  onSubmit={buy}
                  money={quote?.amount ?? 0}
                  side="buy"
                  baseCurrency={base?.is_fiat ? "THB" : "tCO2e"}
                  quoteCurrency={quote?.is_fiat ? "THB" : "tCO2e"}
                  disabled={disabled}
                  type="limit"
                />
              </Buy>
              <Sell>
                <TxForm
                  onSubmit={sell}
                  money={base?.amount ?? 0}
                  side="sell"
                  baseCurrency={base?.is_fiat ? "THB" : "tCO2e"}
                  quoteCurrency={quote?.is_fiat ? "THB" : "tCO2e"}
                  disabled={disabled}
                  type="limit"
                />
              </Sell>
            </BuySellContainer>
          </Tab>
          <Tab
            layoutId="trade"
            key="market"
            label={<TabHeader>Market</TabHeader>}
          >
            <BuySellContainer>
              <Buy>
                <TxForm
                  onSubmit={buy}
                  money={quote?.amount ?? 0}
                  side="buy"
                  baseCurrency={base?.is_fiat ? "THB" : "tCO2e"}
                  quoteCurrency={quote?.is_fiat ? "THB" : "tCO2e"}
                  disabled={disabled}
                  type="market"
                />
              </Buy>
              <Sell>
                <TxForm
                  onSubmit={sell}
                  money={base?.amount ?? 0}
                  side="sell"
                  baseCurrency={base?.is_fiat ? "THB" : "tCO2e"}
                  quoteCurrency={quote?.is_fiat ? "THB" : "tCO2e"}
                  disabled={disabled}
                  type="market"
                />
              </Sell>
            </BuySellContainer>
          </Tab>
        </TabsStyle>
      ) : (
        <DisabledButtonMock>
          <Button
            variant="link"
            as="link"
            to={path.login}
            linkState={{ from: location }}
          >
            Login
          </Button>
          or
          <Button variant="link" as="link" to={path.register}>
            Register
          </Button>
        </DisabledButtonMock>
      )}
    </CardView>
  );
};

export default Trade;
