import { useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { requestEmailOTP, requestMobileNumberOTP, verifyOTP } from "api/users";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import axios from "axios";
import { Spinner } from "react-bootstrap";

import {
  Card,
  TabsBar,
  Tab,
  Button,
  OTPInput,
  IOTPInputValue,
  IOTPInputHandles,
} from "components";
import { useAuth } from "providers";

import { config } from "config";
import { APIError } from "models/generic";
import { apiErrorToast, captureErrorSentry } from "utils";

const { path } = config;

const Container = styled.div`
  background-color: ${(props) => props.theme.baseBackgroundColor};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const CardView = styled(Card)`
  margin: auto;
  width: 540px;
  border: 1px solid ${(props) => props.theme.gray1};
`;

const ContentContainer = styled.div`
  max-width: 350px;
  margin: 40px auto;
`;

const StyledTabs = styled(TabsBar)`
  margin-bottom: 16px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1em;
`;

const Desc = styled.div`
  text-align: center;
`;

const BoldDesc = styled(Desc)`
  font-weight: 700;
`;

const SubDesc = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${(props) => props.theme.gray};
  text-align: center;
  margin: 8px 0 0;
  > button {
    font-size: 0.75rem;
    font-weight: 400;
  }
`;

const Expire = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: ${(props) => props.theme.darkgray200};
  margin: 8px 0;
`;

type ActiveTab = "email" | "mobile_number";

const SecurityVerification = () => {
  const otpInput = useRef<IOTPInputHandles>(null);
  const { state, logout, dispatch } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(["token"]);

  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [activeTab, setActiveTab] = useState<ActiveTab>("email");

  // email
  const [codeSent, setCodeSent] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTime] = useState(0);
  const [countdown, setCountdown] = useState(false);
  const [expire, setExpire] = useState(false);
  const [code, setCode] = useState("");
  const [refCode, setRefCode] = useState("");
  const [error, setError] = useState(false);

  // phone
  const [phoneCodeSent, setPhoneCodeSent] = useState(false);
  const [phoneIsFilled, setPhoneIsFilled] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [phoneTimer, setPhoneTimer] = useState(0);
  const [phoneCountdown, setPhoneCountdown] = useState(false);
  const [phoneExpire, setPhoneExpire] = useState(false);
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneRefCode, setPhoneRefCode] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const handleTabSelect = (key: string) => {
    setActiveTab(key as ActiveTab);
  };

  const handleSentCode = async () => {
    setLoading(true);
    if (!executeRecaptcha) {
      toast.error("reCAPTCHA not loaded. Please refresh the page.");
      return;
    }

    setExpire(false);

    try {
      const recaptchaToken = await executeRecaptcha(`request_email_otp`);
      const res = await requestEmailOTP({
        recaptcha_token: recaptchaToken,
      });
      setRefCode(res.data.reference_code);
      setLoading(false);
      setCodeSent(true);
      setCountdown(true);
      setTime(60);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error);
        captureErrorSentry(error, err, { message: "request email otp error" });
      }
      setLoading(false);
    }
  };

  const handleSentPhoneCode = async () => {
    setPhoneLoading(true);
    if (!executeRecaptcha) {
      toast.error("reCAPTCHA not loaded. Please refresh the page.");
      return;
    }

    setPhoneExpire(false);
    try {
      const recaptchaToken = await executeRecaptcha(
        `request_mobile_number_otp`
      );
      const res = await requestMobileNumberOTP({
        recaptcha_token: recaptchaToken,
      });
      setPhoneRefCode(res.data.reference_code);
      setPhoneLoading(false);
      setPhoneCodeSent(true);
      setPhoneCountdown(true);
      setPhoneTimer(60);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error);
        captureErrorSentry(error, err, { message: "request email otp error" });
      }
      setPhoneLoading(false);
    }
  };

  const handleResend = handleSentCode;
  const handlePhoneResend = handleSentPhoneCode;

  const handleOTPChange = (code: string, { isFilled }: IOTPInputValue) => {
    setCode(code);
    setIsFilled(isFilled);
    if (error) {
      setError(false);
    }
  };

  const handlePhoneOTPChange = (code: string, { isFilled }: IOTPInputValue) => {
    setPhoneCode(code);
    setPhoneIsFilled(isFilled);
    if (phoneError) {
      setPhoneError(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const value = state.user?.email;
    if (value) {
      verifyOTP({
        reference_code: refCode,
        otp: code,
      })
        .then((res) => {
          const { success, session_id: sessionId } = res.data;
          if (success && sessionId) {
            setCookie("token", sessionId, {
              path: "/",
              secure: true,
              sameSite: "strict",
            });
            dispatch({
              type: "SET_SESSION",
              payload: sessionId,
            });
            navigate(path.market, { replace: true });
          } else {
            throw new Error("Invalid OTP");
          }
        })
        .catch((err) => {
          otpInput.current?.clear();
          setError(true);
          setLoading(false);
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            apiErrorToast(error);
          }
        });
    } else {
      toast.error("Please fill in your email or mobile number");
    }
  };

  const handlePhoneSubmit = () => {
    setPhoneLoading(true);
    const value = state.user?.mobile_number;
    if (value) {
      verifyOTP({
        reference_code: phoneRefCode,
        otp: phoneCode,
      })
        .then((res) => {
          const { success, session_id: sessionId } = res.data;
          if (success && sessionId) {
            setCookie("token", sessionId, {
              path: "/",
              secure: true,
              sameSite: "strict",
            });
            dispatch({
              type: "SET_SESSION",
              payload: sessionId,
            });
            navigate(path.market, { replace: true });
          } else {
            throw new Error("Invalid OTP");
          }
        })
        .catch((err) => {
          otpInput.current?.clear();
          setPhoneError(true);
          setPhoneLoading(false);
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            apiErrorToast(error);
          }
        });
    } else {
      toast.error("Please fill in your email or mobile number");
    }
  };

  useLayoutEffect(() => {
    if (!state?.user?.email && !state?.user?.mobile_number) {
      navigate(path.error, {
        state: {
          logout: true,
        },
      });
    }
  }, [navigate, state?.user?.email, state?.user?.mobile_number]);

  useLayoutEffect(() => {
    if (state.user?.id && !state.user.need_2fa) {
      navigate(path.default, { replace: true });
    }
  }, [navigate, state.user?.id, state.user?.need_2fa]);

  useLayoutEffect(() => {
    if (!countdown) {
      return;
    }
    const interval = setInterval(() => {
      if (countdown && timer > 0) {
        setTime(timer - 1);
      } else {
        setCountdown(false);
        setExpire(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown, timer]);

  useLayoutEffect(() => {
    if (!phoneCountdown) {
      return;
    }
    const interval = setInterval(() => {
      if (phoneCountdown && phoneTimer > 0) {
        setPhoneTimer(phoneTimer - 1);
      } else {
        setPhoneCountdown(false);
        setPhoneExpire(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [phoneCountdown, phoneTimer]);

  const getTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    const time = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    return time;
  };

  return (
    <Container>
      <CardView>
        <ContentContainer>
          <Title>Security Verification</Title>
          <StyledTabs
            mode="horizontal-pill"
            onSelect={handleTabSelect}
            activeKey={activeTab}
          >
            {[
              <Tab
                disabled={!state?.user?.email}
                key="email"
                label="Email"
                data-test-id="Email"
              >
                <Desc>Please enter 6 digits code sent to</Desc>
                <BoldDesc>{state?.user?.email}</BoldDesc>
                {refCode && <Desc>Ref: {refCode}</Desc>}
                <SubDesc>
                  Not you?{" "}
                  <Button variant="link" onClick={logout}>
                    logout
                  </Button>
                </SubDesc>
                {expire && (
                  <>
                    <Expire>OTP expired</Expire>
                    <Button
                      block
                      onClick={handleResend}
                      disabled={!!timer || !!phoneTimer}
                    >
                      Resend Code
                    </Button>
                  </>
                )}
                {!expire &&
                  (!codeSent ? (
                    <Button
                      block
                      onClick={handleSentCode}
                      disabled={loading || !!timer || !!phoneTimer}
                    >
                      {loading ? (
                        <Spinner
                          style={{ display: "block" }}
                          animation="border"
                        />
                      ) : (
                        phoneTimer || "Send Code"
                      )}
                    </Button>
                  ) : (
                    <>
                      {countdown && (
                        <Expire>OTP expires in {getTime(timer)}</Expire>
                      )}
                      <OTPInput
                        ref={otpInput}
                        id="pin-code"
                        onChange={handleOTPChange}
                        count={6}
                        invalid={error}
                        loading={loading}
                        type="password"
                      />
                      <Button
                        disabled={!isFilled || loading}
                        block
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </>
                  ))}
              </Tab>,
            ]}
            {/* <Tab
              disabled={!state?.user?.mobile_number}
              key="mobile_number"
              label="Phone Number"
              data-test-id="Phone Number"
            >
              <Desc>We will sent 6 digits code to</Desc>
              <BoldDesc>{state?.user?.mobile_number}</BoldDesc>
              {phoneRefCode && <Desc>Ref: {phoneRefCode}</Desc>}
              <SubDesc>
                Not you?{" "}
                <Button variant="link" onClick={logout}>
                  logout
                </Button>
              </SubDesc>
              {phoneExpire && (
                <>
                  <Expire>OTP expired</Expire>
                  <Button
                    block
                    onClick={handlePhoneResend}
                    disabled={!!timer || !!phoneTimer}
                  >
                    Resend Code
                  </Button>
                </>
              )}
              {!phoneExpire &&
                (!phoneCodeSent ? (
                  <Button
                    block
                    onClick={handleSentPhoneCode}
                    disabled={phoneLoading || !!timer || !!phoneTimer}
                  >
                    {phoneLoading ? (
                      <Spinner
                        style={{ display: "block" }}
                        animation="border"
                      />
                    ) : (
                      timer || "Send Code"
                    )}
                  </Button>
                ) : (
                  <>
                    {phoneCountdown && (
                      <Expire>OTP expires in {getTime(phoneTimer)}</Expire>
                    )}
                    <OTPInput
                      ref={otpInput}
                      id="pin-code"
                      onChange={handlePhoneOTPChange}
                      count={6}
                      invalid={phoneError}
                      loading={phoneLoading}
                      type="password"
                    />
                    <Button
                      disabled={!phoneIsFilled || phoneLoading}
                      block
                      onClick={handlePhoneSubmit}
                    >
                      Submit
                    </Button>
                  </>
                ))}
            </Tab> */}
          </StyledTabs>
        </ContentContainer>
      </CardView>
    </Container>
  );
};

export default SecurityVerification;
