import { HTMLAttributes } from "react";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

import { mediaQuery } from "utils/mediaQueries";
import { config } from "config";

const { path } = config;

const Sidebar = styled.div`
  width: 100%;
  min-width: unset;
  padding: 40px;
  background-color: #252525;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: ${(props) => props.theme.sidebarZIndex};
  ${mediaQuery("mobile")} {
    flex-direction: row;
  }
  ${mediaQuery("desktop")} {
    width: 390px;
    min-width: 390px;
    height: 100vh;
    padding: 56px 46px;
    flex-direction: column;
  }
`;

const AppLogo = styled.img`
  height: 116px;
  margin-bottom: 16px;
  ${mediaQuery("desktop")} {
    width: 100%;
    object-fit: contain;
  }
`;

const Descriptions = styled(Link)`
  color: ${(props) => props.theme.textColorLight};
  font-family: ${(props) => `Kanit,${props.theme.fontFamily}`};
  margin: 0;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.textColorLight};
  }
  ${mediaQuery("mobile")} {
    margin-right: 40px;
  }
  ${mediaQuery("desktop")} {
    margin: 0;
  }
`;

const Heading = styled.div`
  width: 100%;
  height: 49px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  gap: 8px;
  color: ${(props) => props.theme.textColorLight};
`;

const LogoGroup = styled.div`
  width: 100%;
`;

const Logo = styled.img``;

interface Props extends HTMLAttributes<HTMLDivElement> {}

const SideBar = ({ ...rest }: Props) => (
  <Sidebar {...rest}>
    <Descriptions to={path.default}>
      <AppLogo src="/CCXT.svg" />
      <Heading>
        <Logo src="/Hotels.svg" alt="" />
        <Logo src="/EMI.svg" alt="" />
        <Logo src="/UTCC.svg" alt="" />
        <Logo src="/TCEB.svg" alt="" />
        <Logo
          src="/CentralGroup.svg"
          alt=""
          style={{ height: "25px", alignSelf: "center" }}
        />
      </Heading>
    </Descriptions>
    <LogoGroup>
      <div
        style={{
          display: "flex",
          gap: "31px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ fontWeight: "500", fontSize: "16px" }}>Powered by</div>
        <div>
          <Logo src="/Gideon-One-Logo.svg" alt="" style={{ width: "140px" }} />
        </div>
      </div>
    </LogoGroup>
    <div
      style={{
        position: "absolute",
        fontWeight: "400",
        fontSize: "36px",
        right: "-423px",
      }}
    >
      Carbon Credit
      <br /> Exchange of Thailand
    </div>
  </Sidebar>
);

export default SideBar;
