import { string, object, lazy, mixed, ref } from "yup";

import {
  emailSchema,
  fileRequiredSchema,
  mobileSchema,
  passwordSchema,
} from "utils";
import { FileTypes } from "./file";
import { APIResponse } from "./generic";

export interface LoginBody {
  email: string;
  password: string;
  recaptcha_token: string;
}

export interface UserInfo {
  session_id: string;
  need_2fa: boolean;
  id: number;
  account_ownership: string;
  name: string;
  email: string;
  mobile_number: string;
  role: string;
  status: "APPROVE" | "SUSPENDED";
}

export interface LoginResponse extends UserInfo {}

export interface VerifyAccountBody {
  account_id: string;
  verification_code: string;
}

export interface RequestResetPasswordEmailBody {
  email: string;
  recaptcha_token: string;
}

export interface RequestResetPasswordMobileNumberBody {
  mobile_number: string;
  recaptcha_token: string;
}

export interface ResetPasswordBody {
  new_password: string;
  recaptcha_token: string;
}

export interface UserWalletsType {
  amount: number;
  amount_locked: number;
  currency: string;
  user_id: number;
  wallet_id: number;
}

export interface UserBalanceType {
  user_wallet: UserWalletsType[];
}

export interface RefreshTokenResponse {
  session_id: string;
}

export type UserResponses = LoginResponse;

export interface RequestEmailOTPBody {
  email?: string;
  recaptcha_token: string;
}

export interface RequestMobileNumberOTPBody {
  mobile_number?: string;
  recaptcha_token: string;
}

export interface VerifyOTPBody {
  reference_code: string;
  otp: string;
}

export interface VerifyOTPResponse {
  session_id?: string;
  success: boolean;
}

export interface RequestNewUserOTPBody {
  type: "email" | "mobile_number";
  value: string;
  recaptcha_token: string;
}

export interface VerifyNewUserOTPBody {
  type: "email" | "mobile_number";
  value: string;
  reference_code: string;
  otp: string;
}

export enum OwnershipType {
  company = "company",
  individual = "individual",
}

export type NewPaymentMethod = {
  bank_code: string;
  bank_branch: string;
  bank_account_number: string;
  bank_account_type: string;
  bank_account_name: string;
};

export type AttachmentFiles = {
  proof_of_company_registration: FileList | string;
  meeting_minutes: FileList | string;
  retrospective_financial_statement?: FileList | string;
  bank_book: FileList | string;
  director_id_card: FileList | string;
  director_house_registration: FileList | string;
  is_vat_registered: boolean;
  proof_of_vat_registration: FileList | string;
  director_signature: FileList | string;
  is_delegated: boolean;
};

export type AttachmentList = {
  file_type: FileTypes;
  file_name: string;
}[];

export type DelegateAttachmentFiles = {
  proof_of_delegation: FileList | string;
  revenue_stamp: FileList | string;
  assignee_signature: FileList | string;
  assignee_id_card: FileList | string;
  assignee_house_registration: FileList | string;
};

export type DelegateAttachment = AttachmentList;

export type RegisterFiles = {
  files: AttachmentList & DelegateAttachment;
};

export type BaseFile = {
  file_name: string;
  file_type: string;
};

export type FileWithUrl = BaseFile & {
  url: string;
};

export type Files = {
  files: BaseFile[];
};

export interface BasicRegisterParams {
  name: string;
  id_number: string;
  address: string;
}
export type IndividualKYCParams = BasicRegisterParams;
export type CompanyKYCParams = BasicRegisterParams & {
  office_branch: string;
};

export type RegisterParams = IndividualKYCParams | CompanyKYCParams;

export interface ChangeEmailBody {
  reference_code: string;
  otp: string;
  new_email: string;
}

export interface ChangeMobileNumberBody {
  reference_code: string;
  otp: string;
  new_mobile_number: string;
}

export interface AccountSecurity {
  pin_last_changed?: string;
  password_last_changed?: string;
  is_2fa_enabled: boolean;
}

export type AccountSecurityResponse = APIResponse<AccountSecurity>;

export interface Toggle2FABody {
  is_enabled: boolean;
  password: string;
}

export interface VerifyPinBody {
  pin: string;
}

export interface VerifyPinResponse {
  success: boolean;
  nonce: string;
}

export interface ChangePinBody {
  nonce: string;
  new_pin: string;
}

export interface ChangePasswordBody {
  current_password: string;
  new_password: string;
}

export interface VerifyUniquenessResult {
  is_unique: boolean;
}

export type VerifyUniquenessRes = APIResponse<VerifyUniquenessResult>;

export interface verifyResetPasswordTokenBody {
  token: string;
}

// New for CCXT

const FILE_SIZE = 6 * 1024 * 1024; // 6MB

const maxSizeFileSchema = fileRequiredSchema.test(
  "fileSize",
  "Maximum File Size is 6MB",
  (file: FileList) => file && (file.item(0)?.size ?? 0) <= FILE_SIZE
);

const maxFileSizeNotRequiredSchema = mixed()
  .optional()
  .test(
    "fileSize",
    "Maximum File Size is 6MB",
    (file: FileList) => file && (file.item(0)?.size ?? 0) <= FILE_SIZE
  );

const lazySchema = lazy((value) => {
  switch (typeof value) {
    case "string":
      return string().required("This field is required");
    default:
      return maxSizeFileSchema;
  }
});

const lazyNotRequiredSchema = lazy((value) => {
  switch (typeof value) {
    case "string":
      return string().optional();
    default:
      return maxFileSizeNotRequiredSchema;
  }
});

// schema
// => 02_Profile
export const profileFormSchema = object().shape({
  email: emailSchema,
  password: passwordSchema,
  confirm_password: string().oneOf(
    [ref("password"), null],
    "Passwords must match"
  ),
  mobile_number: mobileSchema,
});

// => 03_CompanyInfo
export const companyInfoFormSchema = object().shape({
  name: string().required(),
  juristic_number: string()
    .matches(/^\d{13}$/, "Juristic number must be exactly 13 digits")
    .required(),
  name_of_contact_person: string().required(),
  bank_code: string().required(),
  bank_account_number: string().required(),
});

// => 04_Attachments
export const attachmentFormSchema = object().shape({
  proof_of_company_registration: lazySchema,
  proof_of_vat_registration: lazySchema,
  bank_book: lazySchema,
  director_id_card: lazySchema,
  proof_of_delegation: lazyNotRequiredSchema,
  assignee_id_card: lazyNotRequiredSchema,
});

// interface
// => 02_Profile
export type ProfileForm = {
  account_ownership?: Uppercase<OwnershipType>;
  email: string;
  password: string;
  confirm_password: string;
  mobile_number: string;
};
// => 03_CompanyInfo
export interface CompanyInfoForm {
  name: string;
  juristic_number: string;
  name_of_contact_person: string;
  bank_code: string;
  bank_account_number: string;
}

// => 04_Attachments
export interface AttachmentForm {
  proof_of_company_registration: FileList | string;
  proof_of_vat_registration?: FileList | string;
  bank_book: FileList | string;
  director_id_card: FileList | string;
  proof_of_delegation: FileList | string;
  assignee_id_card: FileList | string;
}

// API

type EKYCParams = {
  name: string;
  juristic_number: string;
  name_of_contact_person: string;
};

type BankAccountParams = NewPaymentMethod;

type ProfileWithPin = ProfileForm & { pin: string };

export type RegisterReq = ProfileWithPin &
  EKYCParams &
  BankAccountParams & {
    files: BaseFile[];
    broker_id: number;
    recaptcha_token: string;
  };
