import { useLayoutEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "@emotion/styled/macro";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  Button,
  IOTPInputValue,
  OTPInput,
  Tab,
  TabsBar,
  TextField,
  TextTitle,
  PhoneInput,
  IOTPInputHandles,
} from "components";
import { config } from "config";
import {
  apiErrorToast,
  captureErrorSentry,
  recaptchaInitErrorToast,
} from "utils";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import { APIError, APIResponse } from "models/generic";
import {
  requestResetPasswordEmail,
  requestResetPasswordMobileNumber,
} from "api/users";
import * as errorCodes from "models/apiErrorCodes";
import { VerifyOTPResponse } from "models/user";

const { path } = config;

const addPhonePrefix = (phone: string) => `+${phone}`;

const TextDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Title = styled(TextTitle)`
  color: #007df7;
  max-width: 18.75rem;
  margin: 0 auto 24px;
  text-align: center;
  font-size: 30px;
`;

const Container = styled.div``;

const Descript = styled.p`
  text-align: center;
  width: 20.5rem;
  margin: 20px auto;
`;

const StyledTabsBar = styled(TabsBar)`
  width: 100%;
  & > .tab-ul-container {
    background-color: transparent;
  }
`;

const Desc = styled.div`
  text-align: center;
`;

const BoldDesc = styled(Desc)`
  font-weight: 700;
`;

const Expire = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: ${(props) => props.theme.darkgray200};
  margin: 8px 0;
`;

const GroupButton = styled.div`
  display: flex;
`;

type ActiveTab = "email" | "mobile_number";

interface FieldValues {
  email: string;
  mobile_number: string;
}

const ForgetPass = () => {
  const [activeTab, setActiveTab] = useState<ActiveTab>("email");

  const schema = yup.object().shape(
    {
      email: yup.string().when("mobile_number", {
        is: () => activeTab === "email",
        then: yup
          .string()
          .email("Please enter a valid email.")
          .required("Email is required"),
      }),
      mobile_number: yup.string().when("email", {
        is: () => activeTab === "mobile_number",
        then: yup
          .string()
          .min(11, "Phone must be at valid")
          .required("Phone number is required"),
      }),
    },
    [["email", "mobile_number"]]
  );
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const otpInput = useRef<IOTPInputHandles>(null);

  const [token, setToken] = useState("");

  // email
  const [emailLinkSent, setEmailLinkSent] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  // phone

  const [phoneCodeSent, setPhoneCodeSent] = useState(false);
  const [phoneIsFilled, setPhoneIsFilled] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [phoneTimer, setPhoneTimer] = useState(0);
  const [phoneCountdown, setPhoneCountdown] = useState(false);
  const [phoneExpire, setPhoneExpire] = useState(false);
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneRefCode, setPhoneRefCode] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const handleSentEmailResetPassword = async () => {
    setEmailLoading(true);
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }
    try {
      const recaptchaToken = await executeRecaptcha(
        `request_reset_password_email`
      );
      await requestResetPasswordEmail({
        email: getValues("email"),
        recaptcha_token: recaptchaToken,
      });
      setEmailLoading(false);
      setEmailLinkSent(true);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        if (error.code === errorCodes.DatabaseError) {
          setError("email", {
            type: "is-found",
            message: "Couldn't find your Account",
          });
        } else {
          apiErrorToast(error);
          captureErrorSentry(error, err, {
            message: "request email otp error",
          });
        }
      }
      setEmailLoading(false);
    }
  };

  const handleSentPhoneResetPassword = async () => {
    setPhoneLoading(true);
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }
    setPhoneExpire(false);
    try {
      const recaptchaToken = await executeRecaptcha(
        `request_reset_password_mobile_number`
      );
      const res = await requestResetPasswordMobileNumber({
        mobile_number: addPhonePrefix(getValues("mobile_number")),
        recaptcha_token: recaptchaToken,
      });
      const { reference_code: referenceCode, session_id: sessionId } = res.data;
      setToken(sessionId);
      setPhoneRefCode(referenceCode);
      setPhoneLoading(false);
      setPhoneCodeSent(true);
      setPhoneCountdown(true);
      setPhoneTimer(60);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        if (error.code === errorCodes.DatabaseError) {
          setError("mobile_number", {
            type: "is-found",
            message: "Couldn't find your Account with this phone number",
          });
        } else {
          apiErrorToast(error);
          captureErrorSentry(error, err, {
            message: "request reset password with mobile number error",
          });
        }
      }
      setPhoneLoading(false);
    }
  };

  const handlePhoneResend = handleSentPhoneResetPassword;

  const handlePhoneOTPChange = (code: string, { isFilled }: IOTPInputValue) => {
    setPhoneCode(code);
    setPhoneIsFilled(isFilled);
    if (phoneError) {
      setPhoneError(false);
    }
  };

  const handlePhoneSubmit = () => {
    setPhoneLoading(true);
    if (getValues("mobile_number")) {
      axios
        .post<APIResponse<VerifyOTPResponse>>(
          `${process.env.REACT_APP_BACKEND_URL}/user/verify-otp`,
          {
            reference_code: phoneRefCode,
            otp: phoneCode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const token = res.data.data.session_id;

          navigate(`/reset-password/${token}`);
        })
        .catch((err) => {
          otpInput.current?.clear();
          setPhoneError(true);
          setPhoneLoading(false);
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            apiErrorToast(error);
          }
        });
    } else {
      toast.error("Please fill in your email or mobile number");
    }
  };

  const onSubmitResetPassword = async () => {
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }
    if (activeTab === "email") {
      handleSentEmailResetPassword();
    } else {
      handleSentPhoneResetPassword();
    }
  };

  const handleClickBack = () => {
    navigate(path.login);
  };

  const getTime = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    const time = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    return time;
  };

  useLayoutEffect(() => {
    if (!phoneCountdown) {
      return;
    }
    const interval = setInterval(() => {
      if (phoneCountdown && phoneTimer > 0) {
        setPhoneTimer(phoneTimer - 1);
      } else {
        setPhoneCountdown(false);
        setPhoneExpire(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [phoneCountdown, phoneTimer]);

  return emailLinkSent ? (
    <Container>
      <Title style={{ width: "20.5rem", height: "auto" }}>
        A password reset link has been sent.
      </Title>
      <TextDiv>
        <Descript>
          Please check your email. You will receive an email with the link to
          reset your password if your email is registered.
        </Descript>
      </TextDiv>
      <Button block onClick={handleClickBack}>
        Close
      </Button>
    </Container>
  ) : (
    <Container>
      <Title>{t("ForgotPassword")}</Title>
      <form
        id="reset-password"
        onSubmit={handleSubmit(onSubmitResetPassword)}
        autoComplete="off"
      >
        <TextDiv>
          <Descript>
            {activeTab === "email"
              ? "Don’t worry. We will send a link to reset password via your registered email."
              : "Please fill in your registered mobile number."}
          </Descript>
        </TextDiv>
        <TextDiv>
          <StyledTabsBar
            mode="horizontal-pill"
            activeKey={activeTab}
            onSelect={(key) => {
              setActiveTab(key as ActiveTab);
              clearErrors();
            }}
            loading={false}
          >
            {[
              <Tab key="email" label="Email">
                <TextField
                  placeholder="Email"
                  errorWarn={!!errors.email}
                  errorMessage={errors.email?.message}
                  disabled={!!phoneTimer}
                  {...register("email")}
                />
                <GroupButton>
                  <Button block variant="link" onClick={handleClickBack}>
                    Cancel
                  </Button>
                  <Button
                    form="reset-password"
                    block
                    disabled={emailLoading || !!phoneTimer}
                  >
                    {emailLoading ? (
                      <Spinner
                        style={{ display: "block" }}
                        animation="border"
                      />
                    ) : (
                      phoneTimer || "Submit"
                    )}
                  </Button>
                </GroupButton>
              </Tab>,
            ]}
            {/* <Tab key="mobile_number" label="Phone Number">
              {phoneExpire && (
                <>
                  <Desc>We will sent 6 digits code to</Desc>
                  <BoldDesc>{addPhonePrefix(watch("mobile_number"))}</BoldDesc>
                  <Expire>OTP expired</Expire>
                  <GroupButton>
                    <Button block variant="link" onClick={handleClickBack}>
                      Cancel
                    </Button>
                    <Button
                      block
                      onClick={handlePhoneResend}
                      disabled={!!phoneTimer}
                    >
                      Resend Code
                    </Button>
                  </GroupButton>
                </>
              )}
              {!phoneExpire &&
                (!phoneCodeSent ? (
                  <>
                    <Controller
                      control={control}
                      name="mobile_number"
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          value={value}
                          onChange={onChange}
                          errorWarn={!!errors.mobile_number}
                          errorMessage={errors.mobile_number?.message}
                        />
                      )}
                    />
                    <GroupButton>
                      <Button block variant="link" onClick={handleClickBack}>
                        Cancel
                      </Button>
                      <Button
                        form="reset-password"
                        block
                        disabled={phoneLoading || !!phoneTimer}
                      >
                        {phoneLoading ? (
                          <Spinner
                            style={{ display: "block" }}
                            animation="border"
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </GroupButton>
                  </>
                ) : (
                  <>
                    <Desc>We will sent 6 digits code to</Desc>
                    <BoldDesc>
                      {addPhonePrefix(watch("mobile_number"))}
                    </BoldDesc>
                    {phoneRefCode && <Desc>Ref: {phoneRefCode}</Desc>}
                    {phoneCountdown && (
                      <Expire>OTP expires in {getTime(phoneTimer)}</Expire>
                    )}
                    <OTPInput
                      ref={otpInput}
                      id="pin-code"
                      onChange={handlePhoneOTPChange}
                      count={6}
                      invalid={phoneError}
                      loading={phoneLoading}
                      type="password"
                    />
                    <Button
                      disabled={!phoneIsFilled || phoneLoading}
                      block
                      onClick={handlePhoneSubmit}
                    >
                      Submit
                    </Button>
                  </>
                ))}
            </Tab> */}
          </StyledTabsBar>
        </TextDiv>
      </form>
    </Container>
  );
};

export default ForgetPass;
