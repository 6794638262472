import styled from "@emotion/styled/macro";
import dayjs from "dayjs";

import { Button, Modal, IModal } from "components";
import { priceNumber } from "utils/numberFormat";
import { OverrideOrder } from "page/Trade/Trade";

const StyledModal = styled(Modal)`
  color: white;
  > .content-container {
    width: 400px;
  }
`;

const CancelButton = styled(Button)`
  margin: 0 4px 0 0;
`;

const BoldText = styled.h2`
  text-align: center;
  margin: 8px 0;
`;
const NormalText = styled.h3`
  margin: 4px 0 24px 0;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface ConfirmCancelOrderProps extends IModal {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  order?: OverrideOrder;
}

export const ConfirmCancelOrder = ({
  onClose,
  onConfirm,
  order,
  ...rest
}: ConfirmCancelOrderProps) => {
  if (!order) return <div />;
  return (
    <StyledModal {...rest}>
      <BoldText>Cancel Order?</BoldText>
      <NormalText>Do you want to cancel this order</NormalText>
      <Flex>
        <div>ID</div>
        <div>{order.id}</div>
      </Flex>
      <Flex>
        <div>Date</div>
        <div>{dayjs(order.submit_time).format("MM-DD HH:mm:ss")}</div>
      </Flex>
      <Flex>
        <div>Side</div>
        <div>{order.side === "buy" ? "Buy" : "Sell"}</div>
      </Flex>
      <Flex>
        <div>Price</div>
        <div>{priceNumber(order.rate)}</div>
      </Flex>
      <Flex>
        <div>Amount</div>
        <div>{order.amount}</div>
      </Flex>
      <Flex>
        <CancelButton variant="secondary" block onClick={onClose}>
          No
        </CancelButton>
        <Button block onClick={onConfirm}>
          Yes
        </Button>
      </Flex>
    </StyledModal>
  );
};
